<template>
  <div class="apply-contact-form">
    <Margins>
      <h6 class="text-normal">
        <span class="header-line">{{ $t('ASK_QUESTION') }}</span>
      </h6>

      <Alert v-if="isSubmitted" class="margins__double">
        <Margins>
          <h5>{{ $t('MESSAGE_SENT') }}</h5>
          <p>{{ $t('MESSAGE_SENT_1') }}</p>
        </Margins>
      </Alert>

      <form
        v-else
        @submit="handleSubmit"
        class="margins__double"
        autocomplete="off"
      >
        <Input
          name="bot-field"
          label="Don’t fill this out if you're human"
          v-model="formData.honeypot"
          autocomplete="random"
          isHoneyPot
        />

        <Margins>
          <Input
            size="small"
            :label="$t('FORM.NAME')"
            name="name"
            autocomplete="name"
            v-model.trim="formData.name"
          />

          <Input
            type="email"
            size="small"
            :label="`${$t('FORM.EMAIL')} *`"
            name="email"
            autocomplete="email"
            v-model.trim="formData.email"
            :error="errors.first('email')"
            v-validate="{ required: true, email: true }"
            :data-vv-as="$t('ASK_QUESTION_VALIADTION.EMAIL')"
          />

          <Input
            type="textarea"
            size="small"
            :label="`${$t('FORM.MESSAGE')} *`"
            name="message"
            :maxlength="maxMessage"
            :count="messageCharactersRemaining"
            v-model.trim="formData.message"
            :error="errors.first('message')"
            v-validate="{ required: true, max: maxMessage }"
            :data-vv-as="$t('ASK_QUESTION_VALIADTION.MESSAGE')"
          />

          <div class="text-center margins__double">
            <Button
              type="submit"
              size="small"
              :loading="isLoading"
              variant="inverse-primary"
              >{{ $t('SEND') }}</Button
            >
          </div>
        </Margins>
      </form>
    </Margins>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ASK_QUESTION } from '@/types';
import { Margins, Input, Button, Alert } from '@/components';

export default {
  name: 'ApplyContactForm',
  components: {
    Margins,
    Input,
    Button,
    Alert,
  },
  data() {
    return {
      formData: {},
      maxMessage: 500,
      isSubmitted: false,
    };
  },
  methods: {
    ...mapActions([ASK_QUESTION]),
    async handleSubmit(e) {
      e.preventDefault();

      if (this.formData.honeypot) {
        return false;
      }

      const valid = await this.$validator.validateAll();
      if (!valid) {
        return false;
      }

      try {
        await this.ASK_QUESTION(this.formData);

        this.$gtag?.event('question_submit');

        this.isSubmitted = true;
        setTimeout(() => {
          this.isSubmitted = false;
        }, 6000);

        this.formData = {};
        this.$validator.reset();
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    ...mapState(['isLoading']),
    messageCharactersRemaining() {
      return this.maxMessage - (this.formData.message || '').length;
    },
  },
};
</script>
